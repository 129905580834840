import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            We want to change the paradigm of investment in Latam, taking companies to success.
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Large imagery is at the heart of this theme</figcaption>
          </figure>
          <h3 id="dynamic-styles">Investment Strategy</h3>
          <p>
            Through our fund, we have a unique investment strategy in which we guarantee an annual return of investment for the fund's partners. 
          </p>
          <p>
            In this way, we guarantee the fund's performance through a hedge, while we make our startups reach the unicorn state. We invest a part in construction and real estate for coverage and another part in the technology we develop.
          </p>
          <p>
            All technology investments are made guaranteeing technological, marketing and business viability through our own companies. In addition, our three-unit methodology where we analyze UX, market segment, patentability and allies in the industry.
          </p>
          <p>
            In this way, we have formed a successful portfolio, with significant returns and results in projects with great future.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "about.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
